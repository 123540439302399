<script setup lang="ts">
import { ref } from 'vue';
import styles from './ModalConfirm.module.scss';

interface Props {
  okText?: string;
  cancelText?: string;
  onOk: (value: boolean) => void;
  onCancel: (value: boolean) => void;
}

const open = ref(true);

const { okText, cancelText, onOk } = defineProps<Props>();
</script>

<template>
  <a-modal
    v-model:open="open"
    centered
    :ok-text="okText"
    :cancel-text="cancelText"
    @ok="onOk"
    @cancel="onCancel"
  >
    <div :class="styles.text"><slot></slot></div>
  </a-modal>
</template>
