<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { storeToRefs } from 'pinia';
import { DefectsList } from '@/entities/Defects';
import type { DefectsListData } from '@/entities/Defects/interfaces';
import { useStorage } from '@/shared/hooks/useStorage';
import { DEFAULT_IM_WERKS, STORAGE_IDLES_LIST } from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { HandleRequest, InformationDialog } from '@/shared/ui';
import { useGetItemsList } from '..';

const { getItem } = useStorage();
const { profile, userIsSupervisor } = storeToRefs(useUserProfileStore());

const { itemsList, filesList, defectsFetchError, idlesFetchError, idlesList, reloadFetchRequest } =
  useGetItemsList(profile.value?.enterprise_sap_code ?? DEFAULT_IM_WERKS);

const newIdlesInList = ref<DefectsListData[]>([]);
const handleCloseErrorModal = () => {
  newIdlesInList.value = [];
};
watchEffect(async () => {
  if (idlesList.value && userIsSupervisor.value) {
    //Для супервайзера нужно сравнивать сохраненные при прошлом старте смены простои с вновь загруженными
    const prevSavedIdles = await getItem(STORAGE_IDLES_LIST);
    const parsedPrevSavedIdles = JSON.parse(prevSavedIdles || '');
    if (parsedPrevSavedIdles && parsedPrevSavedIdles.length) {
      const newIdles = idlesList.value.EX_PROSTOI_LIST.filter(
        item => !parsedPrevSavedIdles?.includes(item.QMNUM),
      );

      if (newIdles.length) {
        newIdlesInList.value = newIdles;
      }
    }
  }
});
</script>

<template>
  <HandleRequest
    :noData="!itemsList?.length"
    :errorRequest="defectsFetchError || idlesFetchError"
    :onRepeat="reloadFetchRequest"
  />
  <DefectsList v-if="itemsList" :items="itemsList" :filesList="filesList" />
  <InformationDialog
    v-if="newIdlesInList.length"
    title="Обнаружены новые простои оборудования"
    :text="newIdlesInList?.map(item => item.EQKTX).join(', \r\n')"
    :onClose="() => handleCloseErrorModal()"
  />
</template>
