import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { useLoadingStore } from '@/shared/model/useLoadingStore';
import { getIdlesList } from '../api/getIdlesList';
import type { IdlesListResponse } from '../interfaces';
import type { IdlesListRequestParams } from '../interfaces/IdlesListRequestParams';

export const useIdlesListStore = defineStore('idlesList', () => {
  const idlesList = ref<IdlesListResponse | undefined>(undefined);
  const idlesFetchError = ref(false);
  const { handleResponse } = useAlertStore();
  const { handleLoading } = useLoadingStore();

  const fetchIdlesListAsync = async (input: IdlesListRequestParams) => {
    const tryBlock = async () => {
      idlesFetchError.value = false;
      const response = await getIdlesList(input);
      if (handleResponse(response)) {
        idlesList.value = response.data as IdlesListResponse;
      } else {
        idlesFetchError.value = true;
      }
    };
    await handleLoading({ tryBlock, funcName: 'fetchIdlesListAsync' });
  };

  return {
    idlesList,
    idlesFetchError,
    fetchIdlesListAsync,
  };
});
