import {
  ClipboardTaskListIcon,
  EngineIcon,
  FlagIcon,
  NfcIcon,
  PeopleCommunityIcon,
  SettingsIcon,
  TruckIcon,
  WarehouseIcon,
} from '@/shared/assets/svg';

export const NAV_ROOT = '/';
export const NAV_DASHBOARD = '/dashboard';
export const NAV_DEFECTS = '/defects';
export const NAV_DEFECT_DETAIL = '/defect-detail';
export const NAV_ORDER_DEFECT_DETAIL = '/order-defect-detail';
export const NAV_SECTORS = '/sectors';
export const NAV_DEFECT_CREATE_FROM_EQUIPMENT = '/defects/create/from-equipment';
export const NAV_DEFECT_CREATE_FROM_OPERATION = '/defects/create/from-operation';
export const NAV_DEFECTS_HISTORY = '/defects/history';
export const NAV_LOGIN = '/login';
export const NAV_EQUIPMENT_DETAIL = '/equipment-detail';
export const NAV_EQUIPMENT_LINES = '/equipment-lines';
export const NAV_EQUIPMENT_LINES_CREATE_DEFECT = '/equipment-lines-create-defect';
export const NAV_EQUIPMENT_SEARCH = '/equipment-search';
export const NAV_EQUIPMENT_SEARCH_NO_NFC = '/equipment-search-no-nfc';
export const NAV_UNITS = '/units';
export const NAV_NFC = '/nfc';
export const NAV_NFC_CREATE_DEFECT = '/nfc-create-defect';
export const NAV_NFC_CREATE_ORDER = '/nfc-create-order';
export const NAV_NFC_ORDER = '/nfc-order';
export const NAV_NFC_LIST = '/nfc-list';
export const NAV_RESERVES = '/reserves';
export const NAV_RESERVES_SEARCH = '/reserves-search';
export const NAV_REVOLVING_FUND = '/revolving-fund';
export const NAV_SHIFT_ORDER = '/shift-order';
export const NAV_SHIFT_FINISH = '/shift-finish';
export const NAV_SHIFT_FINISHED = '/shift-finished';
export const NAV_INSPECTION_DETAIL = '/inspection-detail';
export const NAV_MAINTENANCE_OR_REPAIR = '/maintenance-or-repair';
export const NOT_FOUND = '/404';
export const NAV_ORDER_DOCUMENT_DETAIL = '/order-document-detail';
export const NAV_EQUIPMENT_DOCUMENT_DETAIL = '/equipment-document-detail';
export const NAV_OPERATIONS = '/operations';
export const NAV_OPERATIONS_TAG = '/operations-tag';
export const NAV_OPERATIONS_TAG_SLIDER = '/operations-tag-slider';
export const NAV_OPERATIONS_SLIDER = '/operations-slider';
export const NAV_OPERATION_DETAIL = '/operation-detail';
export const NAV_OPERATIONS_MEASURE = '/operations-measure';
export const NAV_ORDER_CREATE = '/create-order';
export const NAV_ORDER_DEFECT_CREATE = '/create-order-defect';
export const NAV_MATERIAL_SEARCH = '/material-search';
export const NAV_REASSIGNMENT = '/reassignment';
export const NAV_ORDER_HISTORY_DETAIL = '/order-history-detail';
export const NAV_BRIGADES = '/brigades';

const nfc = {
  path: NAV_NFC,
  name: 'Метка',
  icon: NfcIcon,
  iconBg: 'var(--blue-1, #E6F4FF)',
  iconColor: 'var(--blue-6, #4096FF)',
};

const shiftFinish = {
  path: NAV_SHIFT_FINISH,
  name: 'Сдать смену',
  icon: FlagIcon,
  iconBg: 'var(--lime-1, #FCFFE6)',
  iconColor: 'var(--lime-7, #7CB305)',
};

const equipments = {
  path: NAV_EQUIPMENT_SEARCH,
  name: 'Оборудование',
  icon: TruckIcon,
  iconBg: 'var(--purple-1, #F9F0FF)',
  iconColor: 'var(--purple-5, #9254DE)',
};

const defects = {
  path: NAV_SECTORS,
  name: 'Дефекты',
  icon: EngineIcon,
  iconBg: 'var(--magenta-1, #FFF0F6)',
  iconColor: 'var(--magenta-6, #EB2F96)',
};

const reserves = {
  path: NAV_RESERVES,
  name: 'Запасы',
  icon: WarehouseIcon,
  iconBg: 'var(--green-1, #F6FFED)',
  iconColor: 'var(--green-7, #389E0D)',
};

const brigades = {
  path: NAV_BRIGADES,
  name: 'Бригады',
  icon: PeopleCommunityIcon,
  iconBg: 'var(--cyan-1, #E6FFFB)',
  iconColor: 'var( --cyan-7, #08979C)',
};

const executors = {
  path: NAV_BRIGADES,
  name: 'Исполнители',
  icon: PeopleCommunityIcon,
  iconBg: 'var(--cyan-1, #E6FFFB)',
  iconColor: 'var( --cyan-7, #08979C)',
};

export const repairerMenuItems = [nfc, shiftFinish, equipments, defects];

const revolvingFund = {
  path: NAV_REVOLVING_FUND,
  name: 'Оборотный фонд',
  icon: SettingsIcon,
  iconBg: 'var(--red-1, #FFF1F0)',
  iconColor: 'var(--red-5, #FF4D4F)',
};

export const technologistMenuItems = [
  nfc,
  shiftFinish,
  equipments,
  defects,
  // TODO: Раскоментить, когда будем добавлять разделы
  // revolvingFund,
];

export const repairerSupervisorMenuItems = [
  nfc,
  shiftFinish,
  equipments,
  defects,
  reserves,
  brigades,
];
export const technologistSupervisorMenuItems = [
  nfc,
  shiftFinish,
  equipments,
  defects,
  reserves,
  executors,
];

export const employeeMenuItems = [nfc, equipments, defects];

export const createNewEntitiesMenuItems = [
  {
    path: NAV_NFC_CREATE_ORDER,
    name: 'Новое задание',
    icon: ClipboardTaskListIcon,
  },
  {
    path: NAV_NFC_CREATE_DEFECT,
    name: 'Новый дефект',
    icon: EngineIcon,
  },
];

export const noBottomPanelPages = [NAV_ROOT, NAV_SHIFT_FINISHED, NAV_SHIFT_ORDER];
export const transparentHeaderPages = [
  NAV_NFC,
  NAV_SHIFT_FINISHED,
  NAV_NFC_ORDER,
  NAV_NFC_CREATE_DEFECT,
  NAV_NFC_CREATE_ORDER,
];

export const nfcStaticPages = [NAV_NFC, NAV_NFC_CREATE_DEFECT, NAV_NFC_CREATE_ORDER];

export const DEFAULT_ERROR_TEXT = 'Что-то пошло не так, попробуйте снова';

export const DELIMITER = '\r\n';
export const EXTRA_DELIMITER = '\n';
export const MAX_MINUTES_FOR_ORDER = 59;

export const STORAGE_KEY = 'mtoro_';
export const STORAGE_LAST_ROUTE = 'lastRoute';
export const STORAGE_END_ACCEPTED_SHIFT = 'endAcceptedShift';
export const STORAGE_DATA_SYNC = 'dataSync';
export const STORAGE_USERS = 'users';
export const STORAGE_ORDERS_OP = 'ordersOperPlan';
export const STORAGE_ORDERS = 'orders';
export const STORAGE_ORDER_USERS = 'orderUsers';
export const STORAGE_ORDER_SUPERVISOR = 'orderSupervisor';
export const STORAGE_MATERIALS = 'materials';
export const STORAGE_IDLES_LIST = 'idlesList';
export const STORAGE_MOCK_USER_ID = 'mockUserId';
export const STORAGE_MOCK_ORDERS = 'mockOrders';

export const imagesExtension = ['jpg', 'jpeg', 'tiff', 'gif', 'bmp', 'png', 'svg', 'webp'];
export const videosExtension = ['mp4', 'mkv', 'webm', 'ogg', 'ogv'];

export const APP_URL = 'https://qollab.kz/';

// Получение альтернативного номера завода из локального хранилища
export const DEFAULT_IM_WERKS = localStorage.getItem(`${STORAGE_KEY}IM_WERKS`) || '';
